import React from 'react'
import { Helmet } from 'react-helmet'
import { Link, graphql } from 'gatsby'
import { navigate } from '@reach/router'
import {
  Box,
  Button,
  Container,
  Fade,
  Typography,
} from '@material-ui/core'
import Blanket from 'accessa/lib/Utils/BackgroundCoverImage'
import Alert from '@material-ui/lab/Alert'
import Viewport from 'accessa/lib/Utils/Viewport'
import VerticalLines from 'accessa/lib/Utils/VerticalLines'
import { get } from 'lodash'
import { useTranslation } from 'react-i18next'
import Logo from '../components/Logo'

// eslint-disable-next-line
const LanguageLink = ({ children, to }) => (
  <Box mt={1}>
    <Button
      component={Link}
      to={to}
      fullWidth
      variant="contained"
      color="primary"
      size="large"
    >
      {children}
    </Button>
  </Box>
)

const Index = (props) => {
  const [timer, setTimer] = React.useState(null)
  const { t } = useTranslation()

  const clearRedirect = () => {
    clearTimeout(timer)
    setTimer(null)
  }

  // eslint-disable-next-line consistent-return
  React.useLayoutEffect(() => {
    if (typeof window === 'undefined') return undefined

    const pref = localStorage.getItem('preferred_lang')
    if (pref) {
      setTimer(
        setTimeout(() => {
          if (pref) navigate(`/${pref}-ca`)
        }, 5000)
      )
    }
  }, [])

  React.useEffect(() => {
    return () => {
      if (timer) clearTimeout(timer)
    }
  }, [timer])

  return (
    <Viewport style={{ height: '100vh' }}>
      <Blanket
        {...get(
          props,
          'data.contentfulIndex.hero.image',
          {}
        )}
        aria-hidden
      />
      <VerticalLines backgroundColor="#FFF" count="5" />
      <Fade in={Boolean(timer)}>
        <Box
          p={1}
          component="aside"
          position="fixed"
          width="100%"
          zIndex={10}
        >
          <Alert
            severity="info"
            action={
              <Button onClick={clearRedirect}>
                {t('labels:cancel')}
              </Button>
            }
          >
            {t('descriptions:preferenceDetected')}
          </Alert>
        </Box>
      </Fade>
      <Box
        alignItems="center"
        component="main"
        color="primary.contrastText"
        display="flex"
        height="100%"
        position="relative"
        textAlign="center"
      >
        <Container maxWidth="sm">
          <Logo />
          <Helmet
            title="Welcome to Canada Rubber Group"
            meta={[
              {
                name: 'description',
                content:
                  'Please select a language to continue browsing our site',
              },
            ]}
          >
            <html lang="en-ca" />
          </Helmet>
          <Typography
            color="inherit"
            variant="h1"
            style={{ marginBottom: 0 }}
          >
            Canada Rubber Group
          </Typography>
          <Typography color="inherit">
            Please select a language to continue
          </Typography>
          <Box my={6}>
            <LanguageLink to="/en-ca">English</LanguageLink>
            <LanguageLink to="/fr-ca">French</LanguageLink>
          </Box>
        </Container>
      </Box>
    </Viewport>
  )
}

export const query = graphql`
  {
    contentfulIndex {
      hero {
        image {
          fluid {
            src
          }
        }
      }
    }
  }
`

export default Index
